import React from "react";
import NavBar from "../components/NavBar";
import Cube from "../Cube/cube.js";

function Dance() {
	return (
		<div className='bg-[url(assests/bg.jpeg)] bg-cover w-full h-screen md:h-screen'>
			<NavBar />
			<div className='flex justify-center items-center h-screen text-orange-500 font-sans'>
				{/* <Cube /> */}
			</div>
		</div>
	);
}

export default Dance;
