import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
// import { Link } from "react-scroll";
import { Link } from "react-router-dom";
import Logo from "../assests/gold.png";

const NavBar = () => {
	const [nav, setNav] = useState(false);

	const links = [
		{
			id: 1,
			link: "home",
		},
		{
			id: 2,
			link: "about",
		},
		{
			id: 3,
			link: "portfolio",
		},
		{
			id: 4,
			link: "skills",
		},
		{
			id: 5,
			link: "contact",
		},
	];
	return (
		<div className='flex justify-between items-center w-5/7 h-[103px] px-2 m-4  text-black bg-[#2C2727] backdrop-filter backdrop-blur-sm bg-opacity-20 rounded-xl shadow-[rgba(0,0,15,0.5)_10px_5px_4px_0px] '>
			<div>
				<Link to='/'>
					<img
						className='h-[200px] w-[200px] cursor-pointer drop-shadow-2xl '
						src={Logo}
						alt='Logo'
					/>
				</Link>
			</div>
			{/* <div className='flex flex-col'>
				<ul>
					<li>
						<Link to='/dance'>Dance</Link>
					</li>
					<li>
						<Link to='/software'>software</Link>
					</li>
					<li>
						<Link to='/model'>model</Link>
					</li>
				</ul>
			</div> */}
			<ul className='hidden md:flex'>
				{links.map(({ id, link }) => (
					<li
						key={id}
						className='px-4 cursor-pointer capitalize font-medium text-gray-500 hover:scale-105 duration-200 '
					>
						<Link to={link} smooth duration={500}>
							{link}
						</Link>
					</li>
				))}
			</ul>
			<div
				onClick={() => setNav(!nav)}
				className='cursor-pointer  pr-2 z-10 text-gray-500 md:hidden'
			>
				{nav ? <FaIcons.FaTimes size={30} /> : <FaIcons.FaBars size={30} />}
			</div>
			{nav && (
				<ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-500 drop-shadow-xl dark:shadow-red-500'>
					{links.map(({ id, link }) => (
						<li
							key={id}
							className='px-4 cursor-pointer capitalize py-6 text-4xl hover:text-gradient-to-left'
						>
							<Link
								onClick={() => setNav(false)}
								to={link}
								smooth
								duration={500}
							>
								{link}
							</Link>
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default NavBar;
