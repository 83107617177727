import React from "react";
import HeroImage from "../assests/heroImage.png";
import { RiArrowRightSFill } from "react-icons/ri";
import { Link } from "react-scroll";
// import Typed from "typed.js";

const Home = () => {
	// const typed = ["Full Stack Developer", "Model", "Dancer", "Creative"];

	return (
		<div
			name='home'
			className='flex  items-center justify-center h-[109vh] w-full bg-[url(assests/gold.png)]  text-black border-2 border-black '
		>
			<div className='flex items-center justify-center h-3/4 w-3/4 bg-gray-800 rounded-lg bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border-2 border-gray-500 shadow-xl shadow-slate-700'>
				<div
					className='flex flex-col max-w-screen-lg mx-auto  items-center
					justify-center h-4/5 px-4 md:flex-row border-2 border-red-600 '
				>
					<div className='flex flex-1/4 flex-col justify-center h-full border-2 border-blue-700'>
						{/* <h2
						className='text-4xl sm:text-7xl font-bold pb-2
                    '
					>
						I'm a{" "}
						<Typed
							className='typing'
							strings={typed}
							typeSpeed={60}
							loop={true}
							backSpeed={60}
						/>
					</h2> */}
						<p>
							Leveraging creative analytics and problem-solving skills as
							a FullStack Software Engineer gifted at translating basic
							client requirements into technical development plans.
							Motivated to advance and expand my skill set through
							targeted mentorship and large scale projects. Experienced
							in Ruby on Rails, JavaScript, Tailwind CSS, and Bootstrap
							programming.
						</p>
						<div>
							<Link
								to='portfolio'
								smooth
								duration={500}
								className='group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-orange-400 via-orange-600 to-orange-600 cursor-pointer '
							>
								Portfolio
								<span className='group-hover:rotate-90 duration-300'>
									<RiArrowRightSFill size={25} className='ml-1' />
								</span>
							</Link>
						</div>
					</div>
					<div className='border-2 border-green-500 '>
						<img
							src={HeroImage}
							alt='headshot'
							className='rounded-2xl mx-auto md:w-full object-scale-down'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
