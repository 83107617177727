import React from "react";
import NavBar from "../components/NavBar";
// import Triangle from "../Triangle/Triangle";

function Model() {
	let encodedText =
		"aHR0cHM6Ly90bnM0bHBnbXppaXlwbnh4emVsNXNzNW55dTBuZnRvbC5sYW1iZGEtdXJsLnVzLWVhc3QtMS5vbi5hd3MvcmFtcC1jaGFsbGVuZ2UtaW5zdHJ1Y3Rpb25zLw==";
	let decodedText = atob(encodedText);
	console.log(decodedText);

	return (
		<div className='bg-black bg-[url(assests/gold.png)] bg-auto bg-center w-full h-screen'>
			<NavBar />
			<div className='flex justify-center items-center   text-cyan-500 text-center pt-20'>
				{/* <Triangle /> */}
				{/* <div className='bg-[url(assests/bg.jpeg)] bg-cover bg-opacity-20 backdrop-blur-sm rounded-md content-center w-1/2 h-60 border-solid border-2 border-y-zinc-600 border-x-transparent  border-spacing-8'>
					hello
				</div>
				<div className='bg-white/30 backdrop-blur-md shadow-x1 ring-1 ring-gray-900/5 rounded-md content-center w-1/2 h-60 border-spacing-8'>
					hello
				</div> */}
			</div>
		</div>
	);
}
export default Model;
