import React from "react";
import NavBar from "../components/NavBar";
import Background from "../components/Background/Background";

function Software() {
	return (
		// <div className='bg-orange-600 w-full h-screen'>
		// 	<NavBar />
		// 	<div className='justify-center  text-cyan-500 text-center pt-20'>
		// 		{" "}
		// 		Software
		// 	</div>
		// 	;
		// </div>
		<div>
			<h1>Welcome to My 3D App!</h1>
			<Background />
			{/* Your other components and content */}
		</div>
	);
}

export default Software;
