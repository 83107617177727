// Background.js
import React from "react";
import { Canvas } from "@react-three/fiber";
import { Stars } from "@react-three/drei";

const Background = () => {
	return (
		<Canvas style={{ position: "fixed", top: 0, left: 0, zIndex: -1 }}>
			<Stars />
		</Canvas>
	);
};

export default Background;
